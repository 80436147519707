/* eslint-disable */

import sbjs from "sourcebuster";

export default ({ app, store }, inject) => {
  inject('initSourceBuster', function() {
    if (process.client) {
      let sbjs = require('sourcebuster')
      sbjs.init()
    }
  })
  inject('setUtmInCookie', function() {
    if(localStorage.getItem('utmData')){
      return;
    }
    if (process.client) {
      let sbjs = require('sourcebuster')
      sbjs.init()
      let utmData = null

      if (sbjs.get.current.typ === 'utm') {
        utmData = sbjs.get.current
      } else {
        utmData = sbjs.get.first
      }
      let utm = {
        src: utmData.src,
        mdm: utmData.mdm,
        cmp: utmData.cmp,
        cnt: utmData.cnt,
        trm: utmData.trm
      }
      localStorage.setItem('utmData', JSON.stringify(utm));
    }
  })
  inject('getSourceBusterMeta', function () {
    if (process.client) {
      let sbjs = require('sourcebuster')
      sbjs.init()
      let utmData = null

      if (sbjs.get.current.typ === 'utm') {
        utmData = sbjs.get.current
      } else {
        utmData = sbjs.get.first
      }
      if (!utmData){
        utmData = localStorage.getItem('utmData')
      }

      if (store.state.gdpr.decisions.analytics || utmData.src === 'advcake') {
        return {
          utm_source: utmData.src,
          utm_medium: utmData.mdm,
          utm_campaign: utmData.cmp,
          utm_content: utmData.cnt,
          utm_term: utmData.trm,
          ua_client_id: this.getCookie('_ga'),
          ref: this.getUrlParameter('ref'),
          ct_session_id: window.call_value || null,
          ym_uid: this.getCookie('_ym_uid'),
        }
      }
    }

    return {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      utm_term: null,
      ua_client_id: null,
      ref: this.getUrlParameter('ref'),
      ct_session_id: window.call_value || null,
      ym_uid: this.getCookie('_ym_uid'),
    }
  })
}
